import { Badge, PlacementWithLogical, Tooltip } from "@chakra-ui/react"

type ServiceBadgeProps = {
    serviceName: string;
    status: string | null;
    placement?: PlacementWithLogical | undefined;
}
export const ServiceBadge = ({ serviceName, status: label = null, placement = undefined }: ServiceBadgeProps) => {
    var result = label === "Running" ?
        <Badge ml='1' variant="solid" colorScheme="green">
            {serviceName}
        </Badge> : <Badge ml='1' variant="outline" colorScheme="gray" textColor="gray.300">
            {serviceName}
        </Badge>

    if (placement !== undefined) {
        result = <Tooltip hasArrow label={label} placement={placement}>{result}</Tooltip>
    }
    return result
}

